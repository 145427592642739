<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <h2 class="text-2xl font-weight-semibold">
              Reset Password
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2">
            Please input your phone number address so we can send you instructions on how to reset your password
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="form"
            method="post"
            action="/"
            lazy-validation
            @submit.prevent="login()"
          >
            <v-text-field
              v-model="form.phone_number"
              outlined
              label="Phone Number"
              placeholder="07XXXXXXXX"
              hide-details
              :rules="phoneRules"
              required
            ></v-text-field>
            <small
              v-show="form.errors.has('phone_number')"
              class="validation-error"
            >{{ form.errors.get('phone_number') }}</small>
            <v-spacer class="mb-4"></v-spacer>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- login link -->
              <router-link
                :to="{ name: 'hotspot-login', query: queryParams}"
              >
                Back to Login
              </router-link>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
              :loading="form.busy"
            >
              Reset
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      alt="background-img"
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Form from 'vform'
import { mapActions } from 'vuex'
import validationRules from '@/mixins/validationRules'
import routerQueryParamMixin from '@/mixins/routerQueryParamMixin'

export default {
  mixins: [validationRules, routerQueryParamMixin],
  data: () => ({
    emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    passwordRules: [v => !!v || 'Password is required'],
    form: new Form({
      phone_number: '',
    }),
  }),
  setup() {
    const isPasswordVisible = ref(false)

    return {
      isPasswordVisible,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    ...mapActions(['authenticateUser']),
    login() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post('hotspot/password/reset')
          .then(response => {
            this.$router.push({ name: 'hotspot-login', query: this.queryParams })
            this.$toast.success(response.data.message)
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
